import { createSelector } from '@reduxjs/toolkit';

import { useAppSelector } from '../../app/redux/hooks';
import { RootState } from '../../app/redux/reducer';

const selectWorkstation = (state: RootState) => state.appState.workstation;
const selectSite = (state: RootState) => state.appState.site;
const selectEngineInfo = (state: RootState) => state.appState.engineInfo;
const selectOperatorSession = (state: RootState) => state.appState.operatorSession;
const selectAppState = (state: RootState) => state.appState;
const selectSignalrConnected = (state: RootState) => state.appState.signalrConnected;

const selectIsInitialised = createSelector([selectWorkstation, selectSite, selectEngineInfo], (workstation, site, engineInfo) => {
  return workstation !== undefined && site !== undefined && engineInfo !== undefined;
});

const selectServerLastPolled = (state: RootState) => state.appState.serverLastPolled;
const selectMenuDataChanged = (state: RootState) => state.appState.menuDataChanged;
const selectAppStateLoadErrors = (state: RootState) => state.appState.stateLoadErrors;
const selectSignalRPoll = (state: RootState) => state.appState.serverLastPolled;
const selectSignalrStatus = (state: RootState) => state.appState.signalrConnected;
const selectEftState = (state: RootState) => state.appState.site?.eft;
const selectTrainingModeSourceUrl = (state: RootState) => state.appState.trainingModeSourceUrl;
const selectIsTrainingMode = (state: RootState) => state.appState.trainingModeSourceUrl != undefined;
const isSessionBlockNewTransactions = (state: RootState) => state.appState.workstation?.isSessionBlockNewTransactions;
const isAppTill = (state: RootState) => state.appState.workstation?.isAppTill;
const layoutVersionLastPoll = (state: RootState) => state.appState.layoutDefinitionVersionLastPolled;
const packageVersionLastPoll = (state: RootState) => state.appState.packageVersionLastPolled;
const menuVersionLastPoll = (state: RootState) => state.appState.menuVersionLastPolled;

export const useWorkstation = () => useAppSelector(selectWorkstation);
export const useSite = () => useAppSelector(selectSite);
export const useEftState = () => useAppSelector(selectEftState);
export const useEngineInfo = () => useAppSelector(selectEngineInfo);
export const useOperatorSession = () => useAppSelector(selectOperatorSession);
export const useAppState = () => useAppSelector(selectAppState);
export const useAppStateSignalrConnected = () => useAppSelector(selectSignalrConnected);
export const useAppStateIsInitialised = () => useAppSelector(selectIsInitialised);
export const useServerLastPolled = () => useAppSelector(selectServerLastPolled);
export const useAppStateMenuDataChanged = () => useAppSelector(selectMenuDataChanged);
export const useAppStateLoadErors = () => useAppSelector(selectAppStateLoadErrors);
export const useSignalRPoll = () => useAppSelector(selectSignalRPoll);
export const useSignalRStatus = () => useAppSelector(selectSignalrStatus);
export const useTrainingModeSourceUrl = () => useAppSelector(selectTrainingModeSourceUrl);
export const useIsTrainingMode = () => useAppSelector(selectIsTrainingMode);
export const useIsSessionBlockNewTransactions = () => useAppSelector(isSessionBlockNewTransactions);
export const useIsAppTill = () => useAppSelector(isAppTill);
export const useLayoutVersionLastPoll = () => useAppSelector(layoutVersionLastPoll);
export const usePackageVersionLastPoll = () => useAppSelector(packageVersionLastPoll);
export const useMenuVersionLastPoll = () => useAppSelector(menuVersionLastPoll);
