import { useEffect, useState } from 'react';
import Moment from 'react-moment';

import { useAppDispatch } from '../../app/redux/hooks';
import appLogger from '../../common/logger/AppLogger';
import { appSubscriptionService } from '../../common/subscriptions/appSubscriptionService';
import { useWorkstation } from '../appState';

import { useErrorDialogMessage, clearErrorDialog } from './errorDialogSlice';
import './errorDialog.sass';

enum ErrorClickType {
  None = 0,
  Main = 1,
  Alternate = 2,
}

function ErrorDialog() {
  const dispatch = useAppDispatch();
  const errorDialogMessage = useErrorDialogMessage();
  const workstation = useWorkstation();
  const [requireConfirm, setRequireConfirm] = useState(false);
  const [showConfirm, setShowConfirm] = useState(ErrorClickType.None);

  useEffect(() => {
    if (errorDialogMessage) {
      appLogger.warn(
        `Showing Error Message Dialog ${JSON.stringify({
          requireConfirmationOnClose: errorDialogMessage.requireConfirmationOnClose,
          message: errorDialogMessage.message,
          dismissable: errorDialogMessage.dismissible,
          buttonText: errorDialogMessage.buttonText,
          showAlternateButton: errorDialogMessage.showAlternateButton,
        })}`,
      );
      setShowConfirm(ErrorClickType.None);
      if (errorDialogMessage.requireConfirmationOnClose === true) setRequireConfirm(errorDialogMessage.requireConfirmationOnClose);
      else setRequireConfirm(false);
    } else setRequireConfirm(false);
  }, [errorDialogMessage]);

  const dismiss = () => {
    if (requireConfirm === true) {
      setShowConfirm(ErrorClickType.Main);
      appLogger.warn('Error Dialog Dismiss was clicked, and requires confirmation, now showing that');
    } else {
      appSubscriptionService.errorButtonClickedNext(errorDialogMessage?.state);
      dispatch(clearErrorDialog());
      appLogger.warn('Error Dialog Dismiss was clicked, no confirmation required');
    }
  };

  const alternate = () => {
    if (requireConfirm === true) {
      setShowConfirm(ErrorClickType.Alternate);
      appLogger.warn('Error Dialog Alternate was clicked, and requires confirmation, now showing that');
    } else {
      appSubscriptionService.errorButtonAlternateClickedNext(errorDialogMessage?.state);
      dispatch(clearErrorDialog());
      appLogger.warn('Error Dialog Alternate was clicked, no confirmation required');
    }
  };

  const confirmYes = () => {
    if (showConfirm == ErrorClickType.Main) {
      appSubscriptionService.errorButtonClickedNext(errorDialogMessage?.state);
      dispatch(clearErrorDialog());
      appLogger.warn('Error Dialog Confirm to close was clicked, and the main button, now doing that');
    } else if (showConfirm === ErrorClickType.Alternate) {
      appSubscriptionService.errorButtonAlternateClickedNext(errorDialogMessage?.state);
      dispatch(clearErrorDialog());
      appLogger.warn('Error Dialog Confirm to close was clicked, and the alternate button, now doing that');
    } else {
      dispatch(clearErrorDialog());
      appLogger.warn('Error Dialog Confirm to close was clicked, and no button, now doing that');
    }
  };

  const confirmNo = () => {
    setShowConfirm(ErrorClickType.None);
    appLogger.warn('Error Dialog, confirm No was clicked, showing original error message');
  };

  return (
    <>
      {errorDialogMessage && showConfirm === ErrorClickType.None && (
        <div className="pos-modal-portal">
          <div
            className={`pos-modal-overlay ${
              errorDialogMessage.backgroundColor !== undefined ? `pos-modal-background-${errorDialogMessage.backgroundColor}` : ''
            }`}
          >
            <div className="pos-popup pos-popup_role_error">
              <div className="pos-popup__content">
                <div className="pos-popup__icon"></div>
                <div className="pos-popup__info">
                  <h3 className="pos-popup__title">Error!</h3>
                  <p className="pos-popup__text" dangerouslySetInnerHTML={{ __html: errorDialogMessage.message }}></p>
                </div>
                <div className="pos-popup__actions">
                  {errorDialogMessage.dismissible && (
                    <button className="pos-popup__action" onClick={dismiss}>
                      {errorDialogMessage.buttonText ? errorDialogMessage.buttonText : 'Dismiss'}
                    </button>
                  )}
                  {errorDialogMessage.showAlternateButton && (
                    <button onClick={alternate} className="pos-popup__action">
                      {errorDialogMessage.alternateButtonText}
                    </button>
                  )}
                </div>
                <div className="pos-popup__info">
                  <p className="pos-popup__text" style={{ marginTop: '20px', marginBottom: '0', fontSize: '14px', minHeight: 'unset' }}>
                    Till {workstation?.terminalNumber} at <Moment format="DD/MM/yyyy HH:mm:ss" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {errorDialogMessage && showConfirm > ErrorClickType.None && (
        <div className="pos-modal-portal">
          <div
            className={`pos-modal-overlay ${
              errorDialogMessage.backgroundColor !== undefined ? `pos-modal-background-${errorDialogMessage.backgroundColor}` : ''
            }`}
          >
            <div className="pos-popup pos-popup_role_error">
              <div className="pos-popup__content">
                <div className="pos-popup__icon"></div>
                <div className="pos-popup__info">
                  <h3 className="pos-popup__title">Please Confirm!</h3>
                  <p className="pos-popup__text">
                    {errorDialogMessage.confirmationText === undefined && 'Are you sure?'}
                    {errorDialogMessage.confirmationText !== undefined &&
                      showConfirm === ErrorClickType.Main &&
                      errorDialogMessage.confirmationText('main')}
                    {errorDialogMessage.confirmationText !== undefined &&
                      showConfirm === ErrorClickType.Alternate &&
                      errorDialogMessage.confirmationText('alternate')}
                  </p>
                </div>
                <div className="pos-popup__actions">
                  <button className="pos-popup__action" onClick={confirmYes}>
                    Yes
                  </button>
                  <button className="pos-popup__action" onClick={confirmNo}>
                    No
                  </button>
                </div>
                <div className="pos-popup__info">
                  <p className="pos-popup__text" style={{ marginTop: '20px', marginBottom: '0', fontSize: '14px', minHeight: 'unset' }}>
                    Till {workstation?.terminalNumber} at <Moment format="DD/MM/yyyy HH:mm:ss" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default ErrorDialog;
