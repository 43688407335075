import { useState } from 'react';
import Moment from 'react-moment';

import { useAppDispatch } from '../../app/redux/hooks';
import { Table, Transaction } from '../api';

import { setSelectedTable, useSelectedTable } from './tableSlice';

import './table.sass';

export interface TransactionTable {
  table: Table;
  transaction: Transaction | undefined;
}

function TableList({ tables }: { tables: TransactionTable[] }) {
  const dispatch = useAppDispatch();
  const activeTable = useSelectedTable();
  const [index, setIndex] = useState(0);

  const TableButton = ({ position }: { position: number }) => {
    const posNew = position + index;
    if (tables.length >= posNew) {
      const table = tables[posNew - 1];
      return (
        <div
          className={`pos-tables__item${activeTable === undefined || activeTable.id !== table.table.id ? ' is-disabled' : ''}${
            table.table.inUse === true ? ' is-table-in-use' : ''
          }`}
          onClick={() => dispatch(setSelectedTable(table.table))}
        >
          {table.transaction && (
            <div className="pos-tables__item-meta">
              <p className="pos-tables__item-server">{table.transaction.operatorName}</p>
              <p className="pos-tables__item-time">
                <Moment format="HH:mm" date={table.transaction.transactionStartDateTime} />{' '}
              </p>
            </div>
          )}
          <h3 className="pos-tables__item-name">T {table.table.tableNumber}</h3>
          {table.transaction && table.transaction.covers > 0 && (
            <div className="pos-tables__item-meta">
              <p className="pos-tables__item-label">Guests</p>
              <p className="pos-tables__item-number">{table.transaction.covers}</p>
            </div>
          )}
        </div>
      );
    } else return <></>;
  };
  return (
    <>
      <div className="pos-tables__arrow-left">
        <button disabled={index === 0} className="pos-tables__arrow-left-button" onClick={() => setIndex(index - 60)}>
          Previous
        </button>
      </div>
      <div className="pos-tables__list">
        <div className="pos-tables__row">
          <TableButton position={1} />
          <TableButton position={2} />
          <TableButton position={3} />
          <TableButton position={4} />
          <TableButton position={5} />
          <TableButton position={6} />
          <TableButton position={7} />
          <TableButton position={8} />
          <TableButton position={9} />
          <TableButton position={10} />
        </div>
        <div className="pos-tables__row">
          <TableButton position={11} />
          <TableButton position={12} />
          <TableButton position={13} />
          <TableButton position={14} />
          <TableButton position={15} />
          <TableButton position={16} />
          <TableButton position={17} />
          <TableButton position={18} />
          <TableButton position={19} />
          <TableButton position={20} />
        </div>
        <div className="pos-tables__row">
          <TableButton position={21} />
          <TableButton position={22} />
          <TableButton position={23} />
          <TableButton position={24} />
          <TableButton position={25} />
          <TableButton position={26} />
          <TableButton position={27} />
          <TableButton position={28} />
          <TableButton position={29} />
          <TableButton position={30} />
        </div>
        <div className="pos-tables__row">
          <TableButton position={31} />
          <TableButton position={32} />
          <TableButton position={33} />
          <TableButton position={34} />
          <TableButton position={35} />
          <TableButton position={36} />
          <TableButton position={37} />
          <TableButton position={38} />
          <TableButton position={39} />
          <TableButton position={40} />
        </div>
        <div className="pos-tables__row">
          <TableButton position={41} />
          <TableButton position={42} />
          <TableButton position={43} />
          <TableButton position={44} />
          <TableButton position={45} />
          <TableButton position={46} />
          <TableButton position={47} />
          <TableButton position={48} />
          <TableButton position={49} />
          <TableButton position={50} />
        </div>
        <div className="pos-tables__row">
          <TableButton position={51} />
          <TableButton position={52} />
          <TableButton position={53} />
          <TableButton position={54} />
          <TableButton position={55} />
          <TableButton position={56} />
          <TableButton position={57} />
          <TableButton position={58} />
          <TableButton position={59} />
          <TableButton position={60} />
        </div>
      </div>
      <div className="pos-tables__arrow-right">
        <button
          disabled={tables.length <= 60 || tables.length <= index + 60}
          className="pos-tables__arrow-right-button"
          onClick={() => setIndex(index + 60)}
        >
          Next
        </button>
      </div>
    </>
  );
}

export default TableList;
