import { RouteObject } from 'react-router-dom';

import Layout from '../common/components/layout/Layout';
import { RequireAuthentication } from '../features/auth';
import LoginSelect from '../features/auth/components/LoginSelect';
import Error from '../features/error/Error';
import NotFound from '../features/not-found/NotFound';
import NotFoundHandler from '../features/not-found/NotFoundHandler';
import Offline from '../features/offline/Offline';
import AddItem from '../features/order/components/add/AddItem';
import WasteReason from '../features/order/components/add/WasteReason';
import Bookings from '../features/order/components/bookings/Bookings';
import CloseSession from '../features/order/components/close-session/CloseSession';
import WorkstationSessions from '../features/order/components/close-session/WorkstationSessions';
import Loyalty from '../features/order/components/loyalty/Loyalty';
import LoyaltyDetails from '../features/order/components/loyalty/LoyaltyDetails';
import Message from '../features/order/components/message/Message';
import MessageKeyboard from '../features/order/components/message/MessageKeyboard';
import MessagePresetSelect from '../features/order/components/message/MessagePresetSelect';
import Order from '../features/order/components/Order';
import Package from '../features/order/components/package/Package';
import MenuSearch from '../features/order/components/search/MenuSearch';
import SignOutConfirm from '../features/order/components/signout-confirm/SignOutConfirm';
import Void from '../features/order/components/void/Void';
import VoidLogon from '../features/order/components/void/VoidLogon';
import VoidTabletLogon from '../features/order/components/void/VoidTabletLogon';
import VoidTabletPin from '../features/order/components/void/VoidTabletPin';
import WorkstationOrderDeviceOverride from '../features/order/components/workstation-order-device/WorkstationOrderDeviceOverride';
import WorkstationOrderDevices from '../features/order/components/workstation-order-device/WorkstationOrderDevices';
import RefundPinEntryDeviceModal from '../features/sales-review/RefundPinEntryDeviceModal';
import SalesReview from '../features/sales-review/SalesReview';
import SalesReviewChangeTender from '../features/sales-review/SalesReviewChangeTender';
import SalesReviewDetails from '../features/sales-review/SalesReviewDetails';
import Split from '../features/split/Split';
import StartSession from '../features/start-session/StartSession';
import OpenTable from '../features/tables/OpenTable';
import StoreTable from '../features/tables/StoreTable';
import Tables from '../features/tables/Tables';
import TransferTable from '../features/tables/TransferTable';
import PinEntryDeviceModal from '../features/tender/PinEntryDeviceModal';
import Summary from '../features/tender/Summary';
import Tender from '../features/tender/Tender';
import ReloadConfirm from '../features/welcome/ReloadConfirm';
import { ServerOverviewChangeFob } from '../features/welcome/servers-overview/ServerOverviewChangeFob';
import { ServerOverviewDetails } from '../features/welcome/servers-overview/ServerOverviewDetails';
import ServerOverviewPin from '../features/welcome/servers-overview/ServerOverviewPin';
import { ServerOverviewSignOn } from '../features/welcome/servers-overview/ServerOverviewSignOn';
import { ServerOverviewSignOut } from '../features/welcome/servers-overview/ServerOverviewSignOut';
import ServersOverview from '../features/welcome/servers-overview/ServersOverview';
import SessionReview from '../features/welcome/session-review/SessionReview';
import SessionReviewDetails from '../features/welcome/session-review/SessionReviewDetails';
import SessionReviewPin from '../features/welcome/session-review/SessionReviewPin';
import { SessionReviewSignOn } from '../features/welcome/session-review/SessionReviewSignOn';
import SignOn from '../features/welcome/SignOn';
import SignOnFob from '../features/welcome/SignOnFob';
import SignOnNoFob from '../features/welcome/SignOnNoFob';
import SignOnPin from '../features/welcome/SignOnPin';
import SignOnSearch from '../features/welcome/SignOnSearch';
import TrainingMode from '../features/welcome/training-mode/TrainingMode';
import { TrainingModeSignOn } from '../features/welcome/training-mode/TrainingModeSignOn';
import Welcome from '../features/welcome/Welcome';

const Routes = {
  Home: {
    path: '/',
    element: (
      <RequireAuthentication>
        <Layout element={<Welcome />} />
      </RequireAuthentication>
    ),
    children: [
      {
        path: 'sign-on',
        element: <SignOn />,
        children: [
          {
            path: 'search',
            element: <SignOnSearch />,
          },
          {
            path: 'pin',
            element: <SignOnPin />,
          },
          {
            path: 'fob',
            element: <SignOnFob />,
          },
          {
            path: 'no-fob',
            element: <SignOnNoFob />,
          },
        ],
      },
      {
        path: 'reload',
        element: <ReloadConfirm />,
      },
      {
        path: 'servers-overview',
        element: <ServersOverview />,
        children: [
          {
            path: '',
            element: <ServerOverviewSignOn />,
          },
          {
            path: 'pin',
            element: <ServerOverviewPin />,
          },
          {
            path: 'details',
            element: <ServerOverviewDetails />,
            children: [
              {
                path: 'sign-out/:operatorSessionId',
                element: <ServerOverviewSignOut />,
              },
            ],
          },
          {
            path: 'change-fob/:operatorSessionId',
            element: <ServerOverviewChangeFob />,
          },
        ],
      },
      {
        path: 'workstation-sessions',
        element: <SessionReview />,
        children: [
          {
            path: '',
            element: <SessionReviewSignOn />,
          },
          {
            path: 'pin',
            element: <SessionReviewPin />,
          },
          {
            path: 'details',
            element: <SessionReviewDetails />,
          },
        ],
      },
      ,
      {
        path: 'training-mode',
        element: <TrainingMode />,
        children: [
          {
            path: '',
            element: <TrainingModeSignOn />,
          },
        ],
      },
    ],
    errorElement: <Error />,
  },
  Order: {
    path: '/order',
    element: (
      <RequireAuthentication>
        <Layout element={<Order />} />
      </RequireAuthentication>
    ),
    children: [
      {
        path: 'search',
        element: <MenuSearch />,
      },
      {
        path: 'message/:lineItemId',
        element: <Message />,
        children: [
          {
            path: '',
            element: <MessagePresetSelect />,
          },
          {
            path: 'free-text',
            element: <MessageKeyboard />,
          },
        ],
      },
      {
        path: 'add/:menuItemId',
        element: <AddItem />,
      },
      {
        path: 'add-waste',
        element: <WasteReason />,
      },
      {
        path: 'package/:packageId',
        element: <Package />,
      },
      {
        path: 'void',
        element: <Void />,
      },
      {
        path: 'void-logon',
        element: <VoidLogon />,
      },
      {
        path: 'void-tablet-logon',
        element: <VoidTabletLogon />,
      },
      {
        path: 'void-tablet-logon/:operatorId',
        element: <VoidTabletPin />,
      },
      {
        path: 'bookings',
        element: <Bookings />,
      },
      {
        path: 'loyalty',
        element: <Loyalty />,
      },
      {
        path: 'loyalty/details/:transactionId',
        element: <LoyaltyDetails />,
      },
      {
        path: 'sign-off-confirmation',
        element: <SignOutConfirm />,
      },
      {
        path: 'close-session',
        element: <CloseSession />,
      },
      {
        path: 'workstation-sessions',
        element: <WorkstationSessions />,
      },
      {
        path: 'workstation-order-devices',
        element: <WorkstationOrderDevices />,
      },
      {
        path: 'workstation-order-devices/:id',
        element: <WorkstationOrderDeviceOverride />,
      },
    ],
    errorElement: <Error />,
  },
  Tables: {
    path: '/tables',
    element: (
      <RequireAuthentication>
        <Layout element={<Tables />} />
      </RequireAuthentication>
    ),
    children: [
      {
        path: '',
        element: <OpenTable />,
      },
      {
        path: 'store',
        element: <StoreTable />,
      },
    ],
    errorElement: <Error />,
  },
  TableTransfer: {
    path: '/tables/transfer/:tableId',
    element: (
      <RequireAuthentication>
        <Layout element={<TransferTable />} />
      </RequireAuthentication>
    ),
    errorElement: <Error />,
  },
  Tender: {
    path: '/tender',
    element: (
      <RequireAuthentication>
        <Layout element={<Tender />} />
      </RequireAuthentication>
    ),
    children: [
      {
        path: 'eft',
        element: <PinEntryDeviceModal />,
      },
    ],
    errorElement: <Error />,
  },
  Summary: {
    path: '/tender/summary/:transactionId',
    element: (
      <RequireAuthentication>
        <Layout element={<Summary />} />
      </RequireAuthentication>
    ),
    errorElement: <Error />,
  },
  Split: {
    path: '/split',
    element: (
      <RequireAuthentication>
        <Layout element={<Split />} />
      </RequireAuthentication>
    ),
    errorElement: <Error />,
  },
  SalesReview: {
    path: '/sales-review',
    element: (
      <RequireAuthentication>
        <Layout element={<SalesReview />} />
      </RequireAuthentication>
    ),
    errorElement: <Error />,
    children: [
      {
        path: ':id',
        element: <SalesReviewDetails />,
        children: [
          {
            path: 'refund',
            element: <RefundPinEntryDeviceModal />,
          },
          {
            path: 'change-tender',
            element: <SalesReviewChangeTender />,
          },
        ],
      },
    ],
  },
  StartSession: {
    path: '/start-session',
    element: <StartSession />,
  },
  Offline: {
    path: '/offline',
    element: (
      <RequireAuthentication>
        <Offline />
      </RequireAuthentication>
    ),
    errorElement: <Error />,
  },
  Login: {
    path: '/login',
    element: <LoginSelect />,
    errorElement: <Error />,
  },
  NotFound: {
    path: '/not-found',
    element: <NotFound homePath="/" />,
  },
};

const getRoutes = (): RouteObject[] => {
  const routeKeys = Object.keys(Routes);

  const routeList = routeKeys.map((key) => {
    return { ...Routes[key as keyof typeof Routes], errorElement: <Error /> };
  });

  const defaultRoute = {
    path: '*',
    element: <NotFoundHandler notFoundPath={Routes.NotFound.path} />,
  };

  return [...routeList, defaultRoute];
};
export { Routes, getRoutes };
