/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';

import Price from '../../common/components/price/Price';
import { useGetPinEntryDevicesQuery } from '../api';

export default function SalesReviewPinEntryDevice({ process }: { process: (pinEntryDeviceId: number | undefined) => void }) {
  const { data: pinEntryDevices, isLoading: pinEntryDevicesLoading } = useGetPinEntryDevicesQuery();

  const PinEntryDevice = ({ position }: { position: number }) => {
    const index = position - 1;
    if (pinEntryDevices && pinEntryDevices.length >= position) {
      const pinEntryDevice = pinEntryDevices[index];
      return (
        <div className={`pos-pin-entry-devices__device`} onClick={() => process(pinEntryDevice.id)}>
          <h3 className="pos-pin-entry-devices__device-text">{pinEntryDevice.name}</h3>
          <p className="pos-pin-entry-devices__device-meta">
            <span className="pos-pin-entry-devices__device-meta-line">{pinEntryDevice.terminalId}</span>
          </p>
        </div>
      );
    }
    return <div className="pos-pin-entry-devices__device hidden-button"></div>;
  };
  return (
    <div className="pos-modal-portal">
      <div className="pos-modal-overlay" onClick={() => process(undefined)}>
        <div className="pos-modal pos-modal_role_pin-entry-devices">
          <div className="pos-modal__content">
            <div className="pos-pin-entry-devices js-pin-entry-devices">
              <div className="pos-pin-entry-devices__header">
                <h2 className="pos-pin-entry-devices__title">Pin Entry Devices</h2>
                <p className="pos-pin-entry-devices__subtitle">Please select a PED from the list below:</p>
              </div>
              {pinEntryDevicesLoading == false && pinEntryDevices?.length == 0 && (
                <div className="pos-pin-entry-devices__empty">No Pin Entry Devices are available</div>
              )}
              <div className="pos-pin-entry-devices__info">
                <div className="pos-pin-entry-devices__devices">
                  <div className="pos-pin-entry-devices__devices-list">
                    <div className="pos-pin-entry-devices__devices-scroll js-pin-entry-devices-servers-scroll">
                      <div className="pos-pin-entry-devices__devices-row">
                        <div className="pos-pin-entry-devices__devices-group">
                          <div className="pos-pin-entry-devices__devices-row">
                            <PinEntryDevice position={1} />
                            <PinEntryDevice position={2} />
                            <PinEntryDevice position={3} />
                          </div>
                          <div className="pos-pin-entry-devices__devices-row">
                            <PinEntryDevice position={4} />
                            <PinEntryDevice position={5} />
                            <PinEntryDevice position={6} />
                          </div>
                          <div className="pos-pin-entry-devices__devices-row">
                            <PinEntryDevice position={7} />
                            <PinEntryDevice position={8} />
                            <PinEntryDevice position={9} />
                          </div>
                          <div className="pos-pin-entry-devices__devices-row">
                            <PinEntryDevice position={10} />
                            <PinEntryDevice position={11} />
                            <PinEntryDevice position={12} />
                          </div>
                          <div className="pos-pin-entry-devices__devices-row">
                            <PinEntryDevice position={13} />
                            <PinEntryDevice position={14} />
                            <PinEntryDevice position={15} />
                          </div>
                          <div className="pos-pin-entry-devices__devices-row">
                            <PinEntryDevice position={16} />
                            <PinEntryDevice position={17} />
                            <PinEntryDevice position={18} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
